body {
  margin: 0;
  height: 100vh;
  width: 100%;
  /* min-height: 10; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-toggle {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: none;
}

.top-menu {
  display: none !important;
}

.panel-background-color {
  background-color: #f7f7f7;
}
.info-panel-styling {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f7f7f7;
  border-color: #d8d8d8;
  border-style: solid;
  border-right-style: none;
  border-width: 1px;
}

.chat-panel-styling {
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #ffffff;
  border-color: #d8d8d8;
  border-style: solid;
  border-right-style: none;
  border-width: 1px;
}
.chat-card-styling {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 80px; */
  max-height: 150px;
  min-height: 90px;
  background-color: #f7f7f7;
  border-color: #d8d8d8;
  border-bottom-style: solid;
  border-width: 1px;
  cursor: pointer;
}
.chat-card-styling-selected {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 80px; */
  max-height: 150px;
  min-height: 90px;
  background-color: #d7dfe7;
  border-color: #d8d8d8;
  border-bottom-style: solid;
  border-width: 1px;
  cursor: pointer;
}
.chat-card-styling:hover {
  background-color: #d7d7d7;
  /* border-color: #d8d8d8;
  border-style: solid;
  border-width: 1px; */
}
.top-border-styling {
  border-color: #d8d8d8;
  border-top-style: solid;
  border-width: 1px;
}

.bottom-border-styling {
  border-color: #d8d8d8;
  border-bottom-style: solid;
  border-width: 1px;
}

.circle-with-initals {
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  background-color: #888888;
  color: #ffffff;
}
.circle-with-initals-s {
  height: 25px;
  width: 25px;
  font-size: 14px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  background-color: #888888;
  color: #ffffff;
}
.overflow-y-styling {
  /* position: relative; */
  height: 100%;
  flex: 1;
  align-self: stretch;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
  min-height: 0;
  /* height: 50vh; */
  /* margin: 0 auto; */
  /* height: 200px; */
}

.chat-header-height {
  height: 70px;
}

.chat-input-height {
  height: 150px;
}

.message-text-south-format {
  font-weight: 300;
  background-color: #dddddd;
  color: #000000;
  /* height: 60px; */
  /* border-style: solid;
  border-width: 1px; */
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  max-width: 60%;
}

.message-text-north-format {
  font-weight: 300;
  /* background-color: #aebfd0; */
  /* background-color: #d7dfe7; */
  /* background-color: #add1ef; */
  background-color: #c8e0f4;

  color: #000000;
  /* height: 60px; */
  /* border-style: solid;
  border-width: 1px; */
  max-width: 60%;
  border-radius: 20px;
  border-bottom-right-radius: 0px;
}

.msgbox-inner {
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  flex: 1;
  /* width: unset; */
}
.msg-send-inner {
  display: flex;
  flex-direction: row;
  /* width: 100%; */
}
.msg-time-format {
  font-size: 10px;
}

.msg-name-format {
  font-size: 12px;
  font-weight: 600;
  color: #1a3b54;
}
.chats-list-panel-styling {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.fs-7 {
  font-size: 12px;
}
.chat-card-date-text-styling {
  font-size: 12px;
  color: #888888;
}
.chat-users-text-styling {
  font-weight: 600;
  font-size: 11px;
  color: #888888;
}
.font-color-grey {
  color: #888888;
}
.font-color-white {
  color: #ffffff;
}
.font-color-danger {
  color: #ff6106;
}
.font-color-green {
  color: #408558;
}
.font-color-primary {
  color: #465f78;
}
.font-color-secondary {
  color: #1a3b54;
}
.bg-color-selected {
  background-color: #d7dfe7 !important;
}
.bg-color-dark-grey {
  background-color: #47474f !important;
}
.bg-color-grey {
  background-color: #d7d7d7 !important;
}
.bg-color-very-light-blue {
  background-color: #d7dfe7 !important;
}
.bg-color-light-blue {
  background-color: #aebfd0 !important;
}
.border-color-grey {
  border-color: #d7d7d7 !important;
}
.border-color-medium-grey {
  border-color: #a7a7a7 !important;
}
.border-color-dark-grey {
  border-color: #474747 !important;
}
.max-width-200px {
  max-width: 200px;
}

.max-width-300px {
  max-width: 300px;
}
.max-width-400px {
  max-width: 400px;
}
.max-width-500px {
  max-width: 500px;
}

.chat-card-endpoint-image-styling {
  width: 20px;
  height: 20px;
}
.hide-on-responsive {
  display: flex;
}
.settings-menu-element-styling {
  color: #888888;
  cursor: pointer;
  max-height: 150px;
  min-height: 90px;
}
.settings-menu-element-selected-styling {
  color: #555555;
  background-color: #d7dfe7;
  cursor: pointer;
  max-height: 150px;
  min-height: 90px;
}
.settings-menu-element-styling:hover {
  background-color: #d7d7d7;
  color: #555555;
}
.clickable {
  cursor: pointer;
}

.hover-grey {
}
.hover-grey:hover {
  /* background-color: #d7d7d7; */
  background-color: #e7e7e7;
}

.hover-color-white {
}
.hover-color-white:hover {
  /* background-color: #d7d7d7; */
  color: #ffffff;
}

.login-background-image {
  background-image: url("../public/images/login_background.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  /* background-repeat: no-repeat, repeat; */
}

@media (max-width: 767px) {
  .btn-toggle {
    display: flex;
  }
  .top-menu {
    display: flex !important;
  }

  /* .chats-list-panel-styling {
    height: 300px;
  } */
  .chat-panel-styling {
    height: 500px;
    /* height: unset; */
    /* flex: 1; */
  }
  .hide-on-responsive {
    display: none;
  }
  .info-panel-styling {
    height: unset;
    /* max-height: 400px; */
  }
}
